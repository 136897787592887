import React from 'react'
import Layout from '../Components/Layout/Layout'
import TutorsManagementModules from '../Containers/TutorsManagementPagesContainer/TutorsManagementModules/TutorsManagementModules'

const TutorsManagementPage = () => {
    return (
        <Layout>
            <TutorsManagementModules />
        </Layout>
    )
}

export default TutorsManagementPage