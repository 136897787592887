import React from "react";
import Layout from "../Components/Layout/Layout";
import AddAdminContainer from "../Containers/AdminPagesContainer/AddAdminContainer/AddAdminContainer";

const AddAdminPage = () => {
  return (
    <Layout>
      <AddAdminContainer />
    </Layout>
  );
};

export default AddAdminPage;
