import React from 'react'
import Layout from '../Components/Layout/Layout'
import ContentBoardContainer from '../Containers/ContentControlManagementPagesContainer/ContentBoardContainer/ContentBoardContainer'

const ContentControlManagementPage = () => {
    return (
        <Layout>
            <ContentBoardContainer />
        </Layout>
    )
}

export default ContentControlManagementPage