import Layout from '../Components/Layout/Layout'
import SupportBoard from '../Containers/SupportContainer/SupportBoard/SupportBoard'

const Support = () => {
  return (
    <Layout>
      <SupportBoard />
    </Layout>
  )
}

export default Support