import React from 'react'
import Layout from '../Components/Layout/Layout'
import AdminProfileContainer from '../Containers/AdminPagesContainer/AdminProfileContainer/AdminProfileContainer'

const AdminProfilePage = () => {
    return (
        <Layout>
            <AdminProfileContainer />
        </Layout>
    )
}

export default AdminProfilePage