import Layout from '../Components/Layout/Layout'
import SupportTrackingChatDetail from '../Containers/SupportContainer/SupportTrackingChatDetail/SupportTrackingChatDetail'

const SupportTrackingChatDetailPage = () => {
    return (
        <Layout>
            <SupportTrackingChatDetail />
        </Layout>
    )
}

export default SupportTrackingChatDetailPage