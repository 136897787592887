export const ContentAnalyticsData = [
    {
        id: '1',
        module: "Module 1",
        tutor: 'Amirah Oyegoke',
        title: "UI Frameworks",
        status: "approved",
        deadline: '11/01/2024',
        displayOptions: false,
    },
    {
        id: '2',
        module: "Module 2",
        tutor: 'Amirah Oyegoke',
        title: "UI Frameworks",
        status: "approved",
        deadline: '11/01/2024',
        displayOptions: false,
    },
    {
        id: '3',
        module: "Module 3",
        tutor: 'Amirah Oyegoke',
        title: "UI Frameworks",
        status: "revise",
        deadline: '11/01/2024',
        displayOptions: false,
    },
    {
        id: '4',
        module: "Module 4",
        tutor: 'Amirah Oyegoke',
        title: "UI Frameworks",
        status: "revise",
        deadline: '11/01/2024',
        displayOptions: false,
    },
    {
        id: '5',
        module: "Module 5",
        tutor: 'Amirah Oyegoke',
        title: "UI Frameworks",
        status: "pending",
        deadline: '11/01/2024',
        displayOptions: false,
    },
    {
        id: '6',
        module: "Module 6",
        tutor: 'Amirah Oyegoke',
        title: "UI Frameworks",
        status: "pending",
        deadline: '11/01/2024',
        displayOptions: false,
    },
];