import React from 'react'
import Layout from '../Components/Layout/Layout'
import SchoolViewDetailsContainer from '../Containers/SchoolManagementPagesContainer/SchoolViewDetailsContainer/SchoolViewDetailsContainer'


const SchoolCourseViewDetailsPage = () => {
    return (
        <Layout>
            <SchoolViewDetailsContainer />
        </Layout>
    )
}

export default SchoolCourseViewDetailsPage