import amirahTemi from '../Assets/Images/amirahTemi.svg'
import courseImg1 from '../Assets/Images/courseImage1.png';
import courseImg2 from '../Assets/Images/courseImage2.png';

export type studentCoursesType = {
  status: string;
  totalAmount: number;
  paymentData: string;
  courseProgressStatus: string;
  paymentStatus: string;
  enrollProgressPercent: number;
  enrollProgressStatus: string;
  courseImage: string;
  courseTitle: string;
  courseDescription: string;
};

export type studentsDataType = {
  isActive: boolean
  studentId: string
  studentImage: string
  studentName: string
  emailAddress: string
  fileName: string
  status: string
  grade: string
  enrolledDate: string
  about: string
  country: string
  schoolLevel: string
  linkedinProfileUrl: string
  profileImage: string
  course: string
  referralLevel: string
  referralInvite: number
  referralInviteSuccessful: number
  displayOptions: boolean
  enrolledCourseDetails: studentCoursesType[]
};

export const studentsData: studentsDataType[] = [
  {
    isActive: false,
    studentId: '123456',
    studentImage: amirahTemi,
    studentName: 'Olivia Davis',
    emailAddress: 'olivia.davis@example.com',
    fileName: 'Assignment3.txt',
    status: 'Inactive',
    grade: 'Not graded',
    enrolledDate: '20 Aug, 2022',
    about:
      'Future entrepreneur with a passion for business and innovation. Always looking for opportunities to learn and create.',
    country: 'South Africa',
    schoolLevel: 'Postgraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/oliviadavis',
    profileImage: 'https://example.com/images/oliviadavis.jpg',
    course: 'Frontend development course',
    referralLevel: 'Pioneer',
    referralInvite: 10,
    referralInviteSuccessful: 5,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Free',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Machine Learning',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Customer Success Management',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Frontend Development',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '234567',
    studentImage: amirahTemi,
    studentName: 'Daniel Wilson',
    emailAddress: 'daniel.wilson@example.com',
    fileName: 'Assignment3.json',
    status: 'Active',
    grade: '35',
    enrolledDate: '03 Sep, 2022',
    about:
      'Art enthusiast exploring the world of digital art and design. Striving to bring creativity and imagination to life through visual storytelling.',
    country: 'Kenya',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/danielwilson',
    profileImage: 'https://example.com/images/danielwilson.jpg',
    course: 'Frontend development course',
    referralLevel: 'Pioneer',
    referralInvite: 10,
    referralInviteSuccessful: 5,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Machine Learning',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
      {
        status: 'Free',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Customer Success Management',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Frontend Development',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '345678',
    studentImage: amirahTemi,
    studentName: 'Sophia Moore',
    emailAddress: 'sophia.moore@example.com',
    fileName: 'Assignment3.csv',
    status: 'Active',
    grade: '42',
    enrolledDate: '10 Oct, 2022',
    about:
      'Advocate for social justice and equality. Committed to making a positive impact in the community through activism and awareness.',
    country: 'Nigeria',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/sophiamoore',
    profileImage: 'https://example.com/images/sophiamoore.jpg',
    course: 'Backend development course',
    referralLevel: 'Pioneer',
    referralInvite: 10,
    referralInviteSuccessful: 5,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Machine Learning',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Customer Success Management',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Frontend Development',
        courseDescription: 'Ignite your business potential with our resources at ise School of Business. Gain the knowledge and skills to thrive in the dynamic world of commerce. Lead and achieve greatness with essential business skills.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '456789',
    studentImage: amirahTemi,
    studentName: 'Ethan Johnson',
    emailAddress: 'ethan.johnson@example.com',
    fileName: 'Assignment3.docx',
    status: 'Active',
    grade: '80',
    enrolledDate: '15 Jan, 2023',
    about:
      'Tech enthusiast with a passion for coding and problem-solving. Always eager to learn and explore new technologies.',
    country: 'United States',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/ethanjohnson',
    profileImage: 'https://example.com/images/ethanjohnson.jpg',
    course: 'Data Science course',
    referralLevel: 'Elite',
    referralInvite: 20,
    referralInviteSuccessful: 10,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Free',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Machine Learning',
        courseDescription: 'Unlock the potential of data with our comprehensive Data Science course. Gain hands-on experience in data analysis, machine learning, and more.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Deep Learning',
        courseDescription: 'Dive deep into neural networks and advanced machine learning techniques with our Deep Learning course. Explore cutting-edge algorithms and applications.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Python for Data Science',
        courseDescription: 'Master Python programming for data analysis and visualization. Learn to use libraries like Pandas, NumPy, and Matplotlib to manipulate and analyze data effectively.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '567890',
    studentImage: amirahTemi,
    studentName: 'Isabella Martinez',
    emailAddress: 'isabella.martinez@example.com',
    fileName: 'Assignment3.pdf',
    status: 'Active',
    grade: '65',
    enrolledDate: '22 May, 2023',
    about:
      'Aspiring designer with a love for aesthetics and creativity. Constantly seeking inspiration from the world around to create meaningful designs.',
    country: 'Spain',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/isabellamartinez',
    profileImage: 'https://example.com/images/isabellamartinez.jpg',
    course: 'Graphic Design course',
    referralLevel: 'Elite',
    referralInvite: 20,
    referralInviteSuccessful: 10,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Typography Essentials',
        courseDescription: 'Explore the fundamentals of typography and learn how to use type effectively in your designs. Master the art of letterforms, spacing, and layout.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Visual Communication',
        courseDescription: 'Learn the principles of visual communication and how to create compelling designs that effectively convey your message. Explore color theory, composition, and more.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Digital Illustration',
        courseDescription: 'Unlock your creativity with digital illustration. Learn digital painting techniques, character design, and more using popular software like Adobe Illustrator and Procreate.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '678901',
    studentImage: amirahTemi,
    studentName: 'Liam Brown',
    emailAddress: 'liam.brown@example.com',
    fileName: 'Assignment3.pdf',
    status: 'Active',
    grade: '90',
    enrolledDate: '12 Feb, 2023',
    about:
      'Passionate programmer with a knack for problem-solving. Always seeking to push the boundaries of technology and innovation.',
    country: 'Canada',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/liambrown',
    profileImage: 'https://example.com/images/liambrown.jpg',
    course: 'Full Stack Web Development course',
    referralLevel: 'Elite',
    referralInvite: 20,
    referralInviteSuccessful: 15,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'React Development',
        courseDescription: 'Master the fundamentals of React.js and build scalable web applications. Learn about components, state management, and more.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Node.js Development',
        courseDescription: 'Become proficient in server-side development with Node.js. Learn about asynchronous programming, RESTful APIs, and more.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'MongoDB Fundamentals',
        courseDescription: 'Explore the basics of MongoDB and NoSQL databases. Learn how to store, retrieve, and manipulate data using MongoDB.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '789012',
    studentImage: amirahTemi,
    studentName: 'Ava Garcia',
    emailAddress: 'ava.garcia@example.com',
    fileName: 'Assignment3.docx',
    status: 'Active',
    grade: '75',
    enrolledDate: '18 Apr, 2023',
    about:
      'Creative thinker with a passion for design and innovation. Constantly exploring new ways to merge technology and art.',
    country: 'Brazil',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/avagarcia',
    profileImage: 'https://example.com/images/avagarcia.jpg',
    course: 'UI/UX Design course',
    referralLevel: 'Elite',
    referralInvite: 20,
    referralInviteSuccessful: 10,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'User Experience Design',
        courseDescription: 'Learn the principles of user-centered design and create intuitive user experiences. Explore usability testing, wireframing, and prototyping.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'UI Design Fundamentals',
        courseDescription: 'Master the fundamentals of UI design and create visually appealing interfaces. Learn about color theory, typography, and layout design.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Prototyping with Figma',
        courseDescription: 'Harness the power of Figma to create interactive prototypes. Learn how to design, prototype, and collaborate effectively using this popular design tool.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '890123',
    studentImage: amirahTemi,
    studentName: 'Mia Thompson',
    emailAddress: 'mia.thompson@example.com',
    fileName: 'Assignment3.pdf',
    status: 'Active',
    grade: '85',
    enrolledDate: '30 Jul, 2023',
    about:
      'Aspiring entrepreneur with a passion for marketing and branding. Constantly seeking opportunities to innovate and disrupt industries.',
    country: 'Australia',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/miathompson',
    profileImage: 'https://example.com/images/miathompson.jpg',
    course: 'Digital Marketing course',
    referralLevel: 'Elite',
    referralInvite: 20,
    referralInviteSuccessful: 15,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Social Media Marketing',
        courseDescription: 'Learn how to leverage social media platforms to build brand awareness and drive engagement. Explore strategies for content creation, advertising, and analytics.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Search Engine Optimization (SEO)',
        courseDescription: 'Master the art of optimizing websites for search engines and increase organic traffic. Learn about keyword research, on-page optimization, and link building strategies.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Email Marketing Essentials',
        courseDescription: 'Unlock the power of email marketing and build effective campaigns that drive conversions. Learn about segmentation, automation, and best practices for email marketing success.',
      },
    ]
  },
  {
    isActive: false,
    studentId: '901234',
    studentImage: amirahTemi,
    studentName: 'James Nguyen',
    emailAddress: 'james.nguyen@example.com',
    fileName: 'Assignment3.docx',
    status: 'Active',
    grade: '78',
    enrolledDate: '05 Nov, 2023',
    about:
      'Tech enthusiast with a focus on cybersecurity and ethical hacking. Committed to protecting digital assets and ensuring online safety.',
    country: 'Vietnam',
    schoolLevel: 'Undergraduate',
    linkedinProfileUrl: 'https://www.linkedin.com/in/jamesnguyen',
    profileImage: 'https://example.com/images/jamesnguyen.jpg',
    course: 'Cybersecurity course',
    referralLevel: 'Elite',
    referralInvite: 20,
    referralInviteSuccessful: 10,
    displayOptions: false,
    enrolledCourseDetails: [
      {
        status: 'Paid',
        totalAmount: 20.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '2nd installment due',
        enrollProgressPercent: 50,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Network Security Fundamentals',
        courseDescription: 'Explore the basics of network security and learn how to protect networks from cyber threats. Cover topics such as firewalls, VPNs, and intrusion detection systems.',
      },
      {
        status: 'Paid',
        totalAmount: 75.00,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: '1st installment paid 2nd upcoming',
        enrollProgressPercent: 75,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg2,
        courseTitle: 'Ethical Hacking Essentials',
        courseDescription: 'Dive into the world of ethical hacking and learn how to identify vulnerabilities in systems. Gain hands-on experience with penetration testing, social engineering, and more.',
      },
      {
        status: 'Free',
        totalAmount: 25.55,
        paymentData: "19th Oct 2023",
        courseProgressStatus: 'closed',
        paymentStatus: 'Paid in full',
        enrollProgressPercent: 100,
        enrollProgressStatus: 'withdrawn',
        courseImage: courseImg1,
        courseTitle: 'Cybersecurity Best Practices',
        courseDescription: 'Master the best practices for cybersecurity and develop strategies to mitigate cyber threats. Learn about risk management, incident response, and compliance frameworks.',
      },
    ]
  }
];
