import React from 'react'
import Layout from '../Components/Layout/Layout'
import AddStudentContainer from '../Containers/StudentsManagementPagesContainer/AddStudentContainer/AddStudentContainer'

const AddStudentPage = () => {
    return (
        <Layout>
            <AddStudentContainer />
        </Layout>
    )
}

export default AddStudentPage